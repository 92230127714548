
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { default as __Next_Translate_old_getServerSideProps__1949780a82b__ } from '@utils/serverSide/getBasicServerSideProps';
import HomePage from '@screens/Home';
import withEmailVerification from '@utils/hoc/handlers/withEmailVerification';
export {} from '@utils/serverSide/getBasicServerSideProps';
export default withEmailVerification(HomePage);

    async function __Next_Translate__getServerSideProps__1949780a82b__(ctx) {
      const res = await __Next_Translate_old_getServerSideProps__1949780a82b__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1949780a82b__ as getServerSideProps }
  